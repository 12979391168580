<template>
  <div class="px-8 py-8 container" style="max-width: 1150px;margin:0 auto;">
    <div class="flex flex-wrap">
      <div class="w-full sm:w-4/5">
        <TitleComponent
          class="py-8"
          title="UN Impact Test – Test 3 (a) (i)"
          size="large"
        />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="BOE Impact Visible Flame (+) Example Photo"
              size="medium"
            />
            <a
              :href="require('../../assets/UN/go-unimpact-min.jpg')"
              target="_blank"
            >
              <img class="m-auto" src="@/assets/UN/go-unimpact-min.jpg" />
            </a>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              The purpose of completing impact sensitivity tests is to measure
              the sensitiveness of a material to drop-weight impact to determine
              if it is unstable in the form it was tested. The Bureau of
              Explosives (BOE) impact test is applicable to solid and liquid
              substances by using two different sample assemblies.
            </p>
            <TitleComponent
              class="py-8"
              title="Test Variations"
              size="medium"
            />
            <p class="p-light">
              There are six different impact sensitivity tests in the UN Manual
              of Tests and criteria. The UN recommended test is the BAM
              Fallhammer.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent
              class="pt-8"
              title="BOE Key Parameters"
              size="medium"
            />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Key Parameter</th>
                    <th>Objectives</th>
                    <th>Origin</th>
                    <th>Specs</th>
                  </tr>
                  <tr>
                    <td>Drop Mass</td>
                    <td>Source of energy</td>
                    <td></td>
                    <td>Mass standardized to 3.63 kg</td>
                  </tr>
                  <tr>
                    <td>Drop Height</td>
                    <td>Source of energy</td>
                    <td></td>
                    <td>Solids: 10 cm Liquids: 25 cm</td>
                  </tr>
                  <tr>
                    <td>Drop Mass Guides</td>
                    <td>
                      Minimal sliding friction (no binding) which can be
                      qualified by drop time from a specified height
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Sample Assembly</td>
                    <td>
                      Transfer energy from the drop mass into the sample through
                      the plunger, plug, die, casing, and onto the die and anvil
                      (solids) with the plunger and plug guided by the casing
                      and housing. For liquids the energy is transferred through
                      the intermediate pin, striker, and onto the brass cup and
                      anvil with the pin and striker guided by rebound check
                      sleeve, striker housing, and anvil housing.
                    </td>
                    <td></td>
                    <td>
                      For solids: Drawings of the apparatus are shown in the UN
                      Manual (Figures 13.4.1.1 and 13.4.1.2). Drawings do not
                      have dimensions. The weight falls on the plunger and plug
                      assembly. This assembly is in contact with the sample,
                      which in turn is placed on a die and anvil assembly and
                      confined in a cylindrical casing whose inside diameter is
                      just sufficient to permit free movement of the plunger and
                      plug. The sample holder diameter is 5.1 mm.<br />
                      For liquids: The apparatus is similar to that for solids
                      except for the sample assembly. The sample assembly is
                      shown in the UN Manual (Figure 13.4.1.3). The drawing does
                      not have dimensions.
                    </td>
                  </tr>
                  <tr>
                    <td>Mating Surface Finish</td>
                    <td>Provide a consistent impact insult</td>
                    <td></td>
                    <td>Solids: Standardized to 0.8 µm.</td>
                  </tr>
                  <tr>
                    <td>Material and hardness of impact pieces</td>
                    <td>Provide a standardized hardness</td>
                    <td></td>
                    <td>
                      Solids: The plunger, plug, die, casing and anvil are
                      hardened tool steel of hardness 50-55 on the Rockwell C
                      scale.
                    </td>
                  </tr>
                  <tr>
                    <td>Surface contact between mating surfaces</td>
                    <td>Provide a uniform impact surface</td>
                    <td>
                      <small
                        >A contact pattern other than a whole, full circle will
                        result in localized high and low energy points applied
                        to the sample</small
                      >
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Sample quantity</td>
                    <td>
                      Provide a consistent and uniform distribution of material
                      so that energy is not imparted to a localized area
                    </td>
                    <td></td>
                    <td>Solids: 10 mg Liquids: 1 drop</td>
                  </tr>
                  <tr>
                    <td>Test Temperature and Humidity</td>
                    <td>
                      Determine the temperature/ humidity dependence of the
                      sample’s sensitivity as applicable. Energetics typically
                      become more sensitive with an increase in temperature.
                    </td>
                    <td></td>
                    <td>Standardized as ambient temperature and humidity</td>
                  </tr>
                  <tr>
                    <td>Characterization of reaction types</td>
                    <td>
                      Provide consistent standards for judgment and evaluation
                      of a material
                    </td>
                    <td></td>
                    <td>
                      Solids: flame or audible report; Liquids: smoke, flame, or
                      audible report
                    </td>
                  </tr>
                  <tr>
                    <td>Number of trials</td>
                    <td>
                      Adequately determine the relative sensitivity result with
                      the fewest number of trials
                    </td>
                    <td></td>
                    <td>
                      Solids: At least 5 trials; Liquids: At least 1 trial
                    </td>
                  </tr>
                  <tr>
                    <td>Sample condition</td>
                    <td>Repeatably obtain results for a given sample</td>
                    <td></td>
                    <td>Substances are tested in the form received.</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment</th>
                  </tr>
                  <tr>
                    <td>Reaction upon insult</td>
                    <td>Operator detection</td>
                    <td>
                      For solids: The test result is considered “+” if a flame
                      or an audible report is observed in at least 5 out of 10
                      trials at a drop height of 10 cm.<br />
                      For liquids: The test result is considered “+” if smoke,
                      flame, or an audible report is observed in at least 1 out
                      of 10 trials at a drop height of 25 cm.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-8 pb-6" style="background-color:#f7f5f4;">
          <div class="w-full md:w-1/2 md:pr-2">
            <TitleComponent
              class="pt-8"
              title="Example Video of Flame and Audible Report (+)"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/88927935?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo88927935"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
          </div>
        </div>
      </div>
      <UnSideBar class="w-full sm:w-1/5"></UnSideBar>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import UnSideBar from "@/components/UnSideBar";

export default {
  components: {
    TitleComponent,
    UnSideBar
  }
};
</script>

<style lang="scss"></style>
